<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("ACTION_REVIEW") }}
          </h3>
        </div>
      </template>
      <template v-slot:toolbar>
        <div class="card-toolbar">
          <v-btn
            v-if="
              stockAction.stockActionTypeId == 1 &&
                stockAction.stockActionId == null
            "
            color="btn-light-danger"
            class="btn btn-light-danger font-weight-bolder mr-2"
            @click="adjustSupplierStockReceived()"
          >
            <i class="far fa-edit"></i>
            {{ $t("ADJUSTMENT_SUPPLIER") }}
          </v-btn>
          <v-btn
            v-if="
              stockAction.stockActionTypeId == 11 &&
                stockAction.stockActionId == null
            "
            color="btn-light-danger"
            class="btn btn-light-danger font-weight-bolder mr-2"
            @click="adjustWholesale()"
          >
            <i class="far fa-edit"></i>
            {{ $t("ADJUSTMENT_WHOLESALE") }}
          </v-btn>
          <v-btn
            v-if="
              stockAction.stockActionTypeId == 7 &&
                stockAction.stockActionId == null
            "
            color="btn-light-danger"
            class="btn btn-light-danger font-weight-bolder mr-2"
            @click="adjustProduction()"
          >
            <i class="far fa-edit"></i>
            {{ $t("ADJUSTMENT_PRODUCTION") }}
          </v-btn>
          <v-btn
            v-if="
              stockAction.stockActionTypeId == 8 &&
                stockAction.stockActionId == null
            "
            color="btn-light-danger"
            class="btn btn-light-danger font-weight-bolder mr-2"
            @click="adjustRecipe()"
          >
            <i class="far fa-edit"></i>
            {{ $t("ADJUSTMENT_RECIPE") }}
          </v-btn>
          <v-progress-circular
            v-show="isXLSDownloadLoading && stockAction.stockActionTypeId == 5"
            indeterminate
            color="green"
          ></v-progress-circular>
          <v-btn
            v-show="!isXLSDownloadLoading && stockAction.stockActionTypeId == 5"
            color="btn-light-success"
            class="btn btn-light-success font-weight-bolder mr-2"
            @click="getStockScrappingXLS()"
          >
            <i class="far fa-file-excel"></i>
            {{ $t("DOWNLOAD_SCRAPPING_PROTOCOL") }}
          </v-btn>
          <v-progress-circular
            v-show="
              isXLSDownloadLoading &&
                [1, 2, 4, 11].includes(stockAction.stockActionTypeId)
            "
            indeterminate
            color="green"
          ></v-progress-circular>
          <v-btn
            v-show="
              !isXLSDownloadLoading &&
                [1, 2, 4, 11].includes(stockAction.stockActionTypeId)
            "
            color="btn-light-success"
            class="btn btn-light-success font-weight-bolder mr-2"
            @click="exportStockRedirectingXls()"
          >
            <i class="far fa-file-excel"></i>
            {{ $t("DOWNLOAD_PROTOCOL") }}
          </v-btn>
        </div>
      </template>
      <template v-slot:body>
        <b-row v-if="!firstLoader">
          <StockInfoPanels
            :storeName="stockAction.storeName"
            :supplierName="stockAction.supplierName"
            :forDate="stockAction.forDate"
            :userName="stockAction.userName"
            :refStoreName="getRefStoreName()"
            :typeName="stockAction.stockActionTypeName"
          >
          </StockInfoPanels>
          <b-col :sm="12">
            <template>
              <div>
                <v-alert
                  v-if="
                    stockAction.forEcho == 1 &&
                      stockAction.echoAt == null &&
                      stockAction.echoQueueAt == null
                  "
                  prominent
                  type="error"
                >
                  Има проблем със синхронизирането с ECHO, моля
                  <v-btn @click="updateEchoAjur()">ОПИТАЙТЕ ОТНОВО</v-btn> или
                  се свържете с мениджър!
                  <br />
                  <span @click="showEchoApiLog()" style="cursor:pointer"
                    >Виж повече...</span
                  >
                  <div v-if="toggleEchoApiLog == true">
                    Payload: {{ stockAction.apiLog.request_payload }}<br />
                    Response: {{ stockAction.apiLog.response_body }}
                  </div>
                </v-alert>
              </div>
            </template>

            <template>
              <div>
                <v-alert
                  v-if="
                    stockAction.forEcho == 1 &&
                      stockAction.echoAt == null &&
                      stockAction.echoQueueAt != null
                  "
                  prominent
                  type="warning"
                >
                  В момента се изпраща към ECHO
                  <v-btn @click="refreshStockAction()">Презареди</v-btn> за да
                  провериш дали е готово.
                </v-alert>
              </div>
            </template>

            <v-alert
              v-if="stockAction.forEcho == 1 && stockAction.echoAt != null"
              prominent
              type="success"
              >Успешно синхронизиране с ECHO! Дата:
              {{ stockAction.echoAt | formatDateTime }}
              <br />
              <span @click="showEchoApiLog()" style="cursor:pointer"
                >Виж повече...</span
              >
              <div v-if="toggleEchoApiLog == true">
                Payload: {{ stockAction.apiLog.request_payload }}<br />
                Response: {{ stockAction.apiLog.response_body }}
              </div>
            </v-alert>
          </b-col>
          <b-col :sm="12">
            <template>
              <div>
                <v-alert
                  v-if="
                    (stockAction.forEcho == 1 &&
                      stockAction.echoAt != null &&
                      stockAction.forAjur == 1 &&
                      stockAction.ajurAt == null) ||
                      (stockAction.forEcho == 0 &&
                        stockAction.forAjur == 1 &&
                        stockAction.ajurAt == null)
                  "
                  prominent
                  type="error"
                >
                  Има проблем със синхронизирането с Ajur, моля
                  <v-btn @click="updateAjur()">ОПИТАЙТЕ ОТНОВО</v-btn>
                  или се свържете с мениджър!
                </v-alert>
              </div>
            </template>

            <v-alert
              v-if="
                (stockAction.forEcho == 1 &&
                  stockAction.echoAt != null &&
                  stockAction.forAjur == 1 &&
                  stockAction.ajurAt != null) ||
                  (stockAction.forEcho == 0 &&
                    stockAction.forAjur == 1 &&
                    stockAction.ajurAt != null)
              "
              prominent
              type="success"
              >Успешно синхронизиране с Ajur! Дата:
              {{ stockAction.ajurAt | formatDateTime }}</v-alert
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col :sm="12" v-if="!firstLoader">
            <StockActionItemsPreviewTable
              :headers="dynamicHeaders"
              :items="stockAction.items"
              :itemsTotal="stockAction.docTotal"
              :showTotalPrice="showTotalPrice"
              @updateWholesaleItem="updateWholesaleItem"
            >
            </StockActionItemsPreviewTable>
          </b-col>
        </b-row>

        <b-row>
          <b-col :sm="6" v-if="stockAction.stockWholesale">
            <ReviewDocInfo
              :stockAction.sync="stockAction.stockWholesale"
              :heading="
                $t('WHOLESALE') +
                  ' (' +
                  stockAction.stockWholesale.clientName +
                  '- ' +
                  stockAction.stockWholesale.clientStoreName +
                  ')'
              "
            />
          </b-col>
          <b-col :sm="6" v-if="stockAction.stockSupplierPurchase">
            <ReviewDocInfo
              :stockAction.sync="stockAction.stockSupplierPurchase"
              :heading="
                $t('SUPPLIER') +
                  ' (' +
                  stockAction.stockSupplierPurchase.supplierName +
                  ')'
              "
            />
          </b-col>
          <b-col :sm="6" v-if="stockAction.transport">
            <ReviewDocInfo
              :source="'transport'"
              :stockAction.sync="stockAction.transport"
              :heading="
                $t('TRANSPORT') +
                  ' (' +
                  stockAction.transport.forwarderName +
                  ')'
              "
            />
          </b-col>
          <b-col :sm="12">
            <hr />
          </b-col>
          <b-col :sm="6" v-if="stockAction.addon">
            <ReviewDocInfo
              :source="'addon'"
              :stockAction.sync="stockAction.addon"
              :heading="
                $t('ADDON') + ' (' + stockAction.addon.contractorName + ')'
              "
            />
          </b-col>
          <b-col :sm="6" v-if="stockAction.pallet">
            <ReviewDocInfo
              :stockAction.sync="stockAction.pallet"
              :heading="$t('PALLET')"
            />
          </b-col>
          <b-col :sm="6" v-if="stockAction.customs">
            <ReviewDocInfo
              :stockAction.sync="stockAction.customs"
              :heading="
                $t('CUSTOMS') + ' (' + stockAction.customs.customsName + ')'
              "
            />
          </b-col>
        </b-row>
        <b-form>
          <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
          <v-overlay :value="!firstLoader && isLoadingStock == true">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  EXPORT_STOCK_REDIRECTING_XLS,
  FETCH_STOCK_ACTION,
  GET_STOCK_SCRAPPING_PROTOCOL_XLS,
  ADJUST_SUPPLIER_STOCK_RECEIVED,
  ADJUST_WHOLESALE,
  ADJUST_PRODUCTION,
  ADJUST_RECIPE,
  UPDATE_WHOLESALE_ITEM,
  UPDATE_ECHO_AJUR,
  UPDATE_AJUR
} from "@/modules/stock/store/stock.module";

import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";

import StockActionItemsPreviewTable from "@/modules/stock/components/actions/StockActionItemsPreviewTable.vue";

import ReviewDocInfo from "@/modules/stock/components/actions/ReviewDocInfo.vue";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "StockActionReview",
  mixins: [],
  data() {
    return {
      errors: {},
      isXLSDownloadLoading: false,
      firstLoader: true,
      stockAction: {},
      showTotalPrice: false,
      toggleEchoApiLog: false,
      startHeaders: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        }
      ],
      endHeaders: []
    };
  },
  components: {
    KTCard,
    StockInfoPanels,
    StockActionItemsPreviewTable,
    ReviewDocInfo
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock action review", route: { name: "stock-action-review" } }
    ]);

    vm.getStockAction();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    dynamicHeaders() {
      let vm = this;
      let dynamicHeaders = vm.startHeaders;
      if (
        vm.stockAction.stockActionTypeId == 1 ||
        vm.stockAction.stockActionTypeId == 10
      ) {
        //Receiving
        let addHeaders = [
          {
            text: this.$t("RECEIVE"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("SINGLE_PRICE"),
            value: "stockActionItem.singlePrice",
            sortable: true
          },
          {
            text: this.$t("CUSTOMS_PRICE"),
            value: "stockActionItem.customsTotalFee",
            sortable: true
          },
          {
            text: this.$t("TRANSPORT_PRICE"),
            value: "stockActionItem.transportTotalPrice",
            sortable: true
          },
          {
            text: this.$t("PALLET_PRICE"),
            value: "stockActionItem.palletTotalPrice",
            sortable: true
          },
          {
            text: this.$t("ADDON_PRICE"),
            value: "stockActionItem.addonTotalPrice",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("LOT"),
            value: "stockActionItem.lot",
            sortable: true
          },
          {
            text: this.$t("LOT_EXP_DATE"),
            value: "stockActionItem.lotExpDate",
            sortable: true
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders, ...vm.endHeaders];
      }
      if (
        vm.stockAction.stockActionTypeId == 2 ||
        vm.stockAction.stockActionTypeId == 9 ||
        vm.stockAction.stockActionTypeId == 16
      ) {
        //Receiving
        let addHeaders = [
          {
            text: this.$t("RECEIVE"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("SINGLE_PRICE"),
            value: "stockActionItem.singlePrice",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("LOT"),
            value: "stockActionItem.lot",
            sortable: true
          },
          {
            text: this.$t("LOT_EXP_DATE"),
            value: "stockActionItem.lotExpDate",
            sortable: true
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders, ...vm.endHeaders];
      }
      if (vm.stockAction.stockActionTypeId == 3) {
        //Returning
        let addHeaders = [
          {
            text: this.$t("RETURNING"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("SINGLE_PRICE"),
            value: "stockActionItem.singlePrice",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("CUSTOMS_PRICE"),
            value: "stockActionItem.customsTotalFee",
            sortable: true
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders, ...vm.endHeaders];
      }
      if (vm.stockAction.stockActionTypeId == 4) {
        //Redirecting
        let addHeaders = [
          {
            text: this.$t("REDIRECTING"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("LOT"),
            value: "stockActionItem.lot",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("LOT_EXP_DATE"),
            value: "stockActionItem.lotExpDate",
            sortable: true
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders];
        vm.showTotalPrice = false;
      }
      if (vm.stockAction.stockActionTypeId == 5) {
        //Scrapping
        let addHeaders = [
          {
            text: this.$t("SCRAPPING"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("LOT"),
            value: "stockActionItem.lot",
            sortable: true
          },
          {
            text: this.$t("LOT_EXP_DATE"),
            value: "stockActionItem.lotExpDate",
            sortable: true
          },
          {
            text: this.$t("SCRAPPING_REASON"),
            value: "scrapping.stockScrappingReasonName",
            sortable: true,
            width: "20%"
          },
          {
            text: this.$t("SCRAPPING_TYPE"),
            value: "scrapping.stockScrappingTypeName",
            sortable: true,
            width: "20%"
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders, ...vm.endHeaders];
      }
      if (vm.stockAction.stockActionTypeId == 6) {
        //Testing
        let addHeaders = [
          {
            text: this.$t("TESTING"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("LOT"),
            value: "stockActionItem.lot",
            sortable: true
          },
          {
            text: this.$t("LOT_EXP_DATE"),
            value: "stockActionItem.lotExpDate",
            sortable: true
          },
          {
            text: this.$t("TESTING_REASON"),
            value: "testing.stockTestingReasonName",
            sortable: true,
            width: "50%"
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders];
        vm.showTotalPrice = false;
      }
      if (
        vm.stockAction.stockActionTypeId == 7 ||
        vm.stockAction.stockActionTypeId == 14
      ) {
        //Testing
        let addHeaders = [
          {
            text: this.$t("PRODUCTION"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("LOT"),
            value: "stockActionItem.lot",
            sortable: true
          },
          {
            text: this.$t("LOT_EXP_DATE"),
            value: "stockActionItem.lotExpDate",
            sortable: true
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders];
        vm.showTotalPrice = false;
      }
      if (
        vm.stockAction.stockActionTypeId == 8 ||
        vm.stockAction.stockActionTypeId == 15
      ) {
        //Testing
        let addHeaders = [
          {
            text: this.$t("RECIPE"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("LOT"),
            value: "stockActionItem.lot",
            sortable: true
          },
          {
            text: this.$t("LOT_EXP_DATE"),
            value: "stockActionItem.lotExpDate",
            sortable: true
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders];
        vm.showTotalPrice = false;
      }
      if (
        vm.stockAction.stockActionTypeId == 11 ||
        vm.stockAction.stockActionTypeId == 12
      ) {
        //Wholesale
        let addHeaders = [
          {
            text: this.$t("WHOLESALE"),
            value: "stockActionItem.unitValue",
            sortable: true
          },
          {
            text: this.$t("SINGLE_PRICE"),
            value: "stockActionItem.singlePrice",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE"),
            value: "stockActionItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("SINGLE_PRICE_WHOLESALE"),
            value: "stockWholesaleItem.singlePrice",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE_WHOLESALE"),
            value: "stockWholesaleItem.totalPrice",
            sortable: true
          },
          {
            text: this.$t("SINGLE_PRICE_CURRENCY_WHOLESALE"),
            value: "stockWholesaleItem.singlePriceCurrency",
            sortable: true
          },
          {
            text: this.$t("TOTAL_PRICE_CURRENCY_WHOLESALE"),
            value: "stockWholesaleItem.totalPriceCurrency",
            sortable: true
          },
          {
            text: this.$t("LOT"),
            value: "stockActionItem.lot",
            sortable: true
          },
          {
            text: this.$t("LOT_EXP_DATE"),
            value: "stockActionItem.lotExpDate",
            sortable: true
          },
          {
            align: "right",
            text: "",
            value: "actions",
            sortable: false,
            width: "10%"
          }
        ];
        dynamicHeaders = [...dynamicHeaders, ...addHeaders, ...vm.endHeaders];
      }
      return dynamicHeaders;
    }
  },
  methods: {
    getStockAction() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_STOCK_ACTION, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.stockAction = data.data;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "stock-actions-list" });
          }
        });
    },
    getStockScrappingXLS() {
      let vm = this;
      vm.isXLSDownloadLoading = true;
      let params = {
        stockActionId: this.$route.params.id,
        storeId: vm.stockAction.storeId
      };
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(GET_STOCK_SCRAPPING_PROTOCOL_XLS, apiParams)
        .then(data => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              "scrapping-protocol-" +
              vm.stockAction.forDate +
              "-" +
              vm.stockAction.storeId;
            link.click();
            link.remove();
            if (vm.isXLSDownloadLoading === true) {
              vm.isXLSDownloadLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    exportStockRedirectingXls() {
      let vm = this;

      vm.isXLSDownloadLoading = true;
      let fileName =
        ([1, 2].includes(vm.stockAction.stockActionTypeId)
          ? "stock-receiving-note-"
          : "stock-movement-note-") + this.$route.params.id;
      vm.isLoadingStoreDailyTasks = true;
      let params = {
        stockActionId: this.$route.params.id,
        storeId: vm.stockAction.storeId
      };
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(EXPORT_STOCK_REDIRECTING_XLS, apiParams)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            link.remove();
            if (vm.isXLSDownloadLoading === true) {
              vm.isXLSDownloadLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    adjustSupplierStockReceived() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(ADJUST_SUPPLIER_STOCK_RECEIVED, {
              stockActionId: vm.stockAction.id
            })
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.firstLoader = false;
              vm.$nextTick(function() {
                vm.stockAction = data.data;
              });
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    adjustWholesale() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(ADJUST_WHOLESALE, {
              stockActionId: vm.stockAction.id
            })
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.firstLoader = false;
              vm.$nextTick(function() {
                vm.stockAction = data.data;
              });
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    adjustProduction() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(ADJUST_PRODUCTION, {
              stockActionId: vm.stockAction.id
            })
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.firstLoader = false;
              vm.$nextTick(function() {
                vm.stockAction = data.data;
              });
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    updateWholesaleItem(item) {
      let vm = this;
      vm.$store
        .dispatch(UPDATE_WHOLESALE_ITEM, {
          wholeSaleItem: item.stockWholesaleItem
        })
        .then(data => {
          vm.getStockAction();
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
        })
        .catch(function(response) {
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    refreshStockAction() {
      let vm = this;
      vm.getStockAction();
    },
    updateEchoAjur() {
      let vm = this;
      vm.stockAction["type"] = 1;
      let payload = {
        id: vm.stockAction.id
      };
      this.$store
        .dispatch(UPDATE_ECHO_AJUR, payload)
        .then(() => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.getStockAction();
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
          }
        });
    },
    updateAjur() {
      let vm = this;
      let payload = {
        id: vm.stockAction.id
      };
      this.$store
        .dispatch(UPDATE_AJUR, payload)
        .then(() => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.getStockAction();
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
          }
        });
    },
    showEchoApiLog() {
      this.toggleEchoApiLog = !this.toggleEchoApiLog;
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getRefStoreName() {
      let vm = this;
      let stockPurchaseOrder = vm.stockAction.stockPurchaseOrder;
      if (vm.stockAction.stockActionTypeId == 2 && stockPurchaseOrder) {
        return stockPurchaseOrder.toStoreName;
      } else if (vm.stockAction.stockActionTypeId == 4 && stockPurchaseOrder) {
        return stockPurchaseOrder.fromStoreName;
      } else if (vm.stockAction.stockActionTypeId == 9 && stockPurchaseOrder) {
        return stockPurchaseOrder.toStoreName;
      } else if (vm.stockAction.stockActionTypeId == 16 && stockPurchaseOrder) {
        return stockPurchaseOrder.fromStoreName;
      }
    }
  }
};
</script>
