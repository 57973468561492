<template>
  <button
    @click="$emit('save')"
    class=" font-weight-bolder mr-2"
    :class="(isLoading ? 'spinner spinner-right ' : '') + colorClass"
    :disabled="isLoading || isDisabled"
  >
    <i :class="iconClass"></i> {{ label }}
  </button>
</template>
<script>
export default {
  name: "CustomButton",
  inheritAttrs: false,
  props: {
    /**
     * Loader Spinner
     */
    isLoading: Boolean,
    isDisabled: Boolean,
    /**
     * Default action
     * e.g. next, continue, exit
     */
    defaultAction: String,
    colorClass: {
      type: String,
      default: "btn btn-success"
    },
    iconClass: {
      type: String,
      default: "far fa-save"
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {}
};
</script>
