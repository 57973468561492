<template>
  <div>
    <v-simple-table
      ><thead>
        <tr>
          <th colspan="2">
            <template
              v-if="
                (source == 'transport' && stockAction.invoiceId == null) ||
                  (source == 'addon' && stockAction.invoiceId == null)
              "
            >
              <v-select
                v-if="source == 'transport' && stockAction.invoiceId == null"
                hide-details
                :items="forwarders"
                item-text="name"
                item-value="id"
                clearable
                :label="$t('FORWARDER')"
                v-model="stockAction.forwarderId"
              ></v-select>

              <v-select
                v-if="source == 'addon' && stockAction.invoiceId == null"
                hide-details
                :items="contractors"
                item-text="name"
                item-value="id"
                clearable
                :label="$t('CONTRACTOR')"
                v-model="stockAction.contractorId"
              ></v-select>
            </template>
            <h3 v-else>
              {{ heading }}
            </h3>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ $t("DOC_NUM") }}</td>
          <td>
            <template>
              <v-text-field
                v-if="stockAction.invoiceId == null"
                v-model="stockAction.stockDocData.docNum"
                :label="$t('DOC_NUM')"
                hide-details
              ></v-text-field>
              <span v-else>{{ stockAction.stockDocData.docNum }}</span>
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("DOC_DATE") }}</td>
          <td>
            <template>
              <FormDatepickerBase
                v-if="stockAction.invoiceId == null"
                :type="'text'"
                :i18n="{
                  label: 'DOC_DATE',
                  placeholder: $t('DOC_DATE')
                }"
                :date.sync="stockAction.stockDocData.docDate"
              ></FormDatepickerBase>
              <span v-else>{{ stockAction.stockDocData.docDate }}</span>
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("VAT_GROUPS") }}</td>
          <td>
            <v-select
              v-if="stockAction.invoiceId == null"
              hide-details
              :items="vatGroups"
              item-text="name"
              item-value="id"
              :label="$t('VAT_GROUPS')"
              v-model="stockAction.stockDocData.docVatGroupId"
            ></v-select>
            <template v-else>{{
              stockAction.stockDocData.docVatRate
            }}</template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("CURRENCY") }}</td>
          <td>
            {{ stockAction.stockDocData.currencyName }} ({{
              stockAction.stockDocData.currencyCode
            }})
          </td>
        </tr>
        <tr>
          <td>{{ $t("TOTAL") }}</td>
          <td>{{ stockAction.stockDocData.docTotalCurrency }}</td>
        </tr>
        <tr>
          <td>{{ $t("VAT") }} ({{ stockAction.stockDocData.docVatRate }}%)</td>
          <td>{{ stockAction.stockDocData.docVatCurrency }}</td>
        </tr>
        <tr>
          <td>{{ $t("TOTAL_WITH_VAT") }}</td>
          <td>{{ stockAction.stockDocData.docTotalWithVatCurrency }}</td>
        </tr>
        <tr>
          <td>{{ $t("TOTAL_BGN") }}</td>
          <td>{{ stockAction.stockDocData.docTotal }}</td>
        </tr>
        <tr>
          <td>
            {{ $t("VAT_BGN") }} ({{ stockAction.stockDocData.docVatRate }}%)
          </td>
          <td>{{ stockAction.stockDocData.docVat }}</td>
        </tr>
        <tr>
          <td>{{ $t("TOTAL_WITH_VAT_BGN") }}</td>
          <td>{{ stockAction.stockDocData.docTotalWithVat }}</td>
        </tr>
        <tr>
          <td>{{ $t("INVOICE_DOC_NUM") }}</td>
          <td>
            <template v-if="stockAction.invoice">
              <router-link
                v-if="checkIfUserHasPermission(['stock-actions-show'])"
                :to="{
                  name: 'stock-invoice-review',
                  params: { id: stockAction.invoice.id }
                }"
              >
                {{ stockAction.invoice.invoiceNumber }}
              </router-link>
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("INVOICE_DOC_DATE") }}</td>
          <td>
            <template v-if="stockAction.invoice">
              {{ stockAction.invoice.issueDate | formatDateTime }}
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t("REG_NUM") }}</td>
          <td>
            {{ stockAction.invoice ? stockAction.invoice.regNum : "" }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("REG_DESCRIPTION") }}</td>
          <td>
            {{ stockAction.invoice ? stockAction.invoice.regDescription : "" }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("REG_DATE") }}</td>
          <td>
            <template v-if="stockAction.invoice">
              {{ stockAction.invoice.regAt | formatDateTime }}
            </template>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-row class="d-flex align-center mt-5" dense>
      <v-col cols="6" class="text-start"> </v-col>

      <v-col cols="6" class="text-end">
        <CustomButton
          :isDisabled="stockAction.invoiceId != null"
          :label="$t('SAVE')"
          :iconClass="'far fa-save'"
          :colorClass="'btn btn-success'"
          v-on:save="onSave($event)"
        ></CustomButton>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _ from "lodash";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import CustomButton from "@/view/content/forms/components/CustomButton.vue";
import { UPDATE_STOCK_DOC_DATA } from "@/modules/stock/store/stock.module";
import { UPDATE_STOCK_ACTION_TRANSPORT_FORWARDER_ID } from "@/modules/stock/store/stock.module";
import { UPDATE_STOCK_ACTION_ADDON_CONTRACTOR_ID } from "@/modules/stock/store/stock.module";
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
export default {
  name: "ReviewDocInfo",
  mixins: [permissionMixin],
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    stockAction: {
      type: Object,
      default: function() {
        return {};
      }
    },
    heading: {
      type: String,
      default: ""
    },
    source: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      forwarders: [],
      contractors: [],
      vatGroups: []
    };
  },
  components: { FormDatepickerBase, CustomButton },
  mounted() {
    let vm = this;
    // if (vm.source == "transport" || vm.source == "addon") {
    vm.getSelects();
    // }
  },
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    payload() {
      let vm = this;
      let payload = {};
      payload.docNum = vm.stockAction.stockDocData.docNum;
      payload.docDate = vm.stockAction.stockDocData.docDate;
      payload.docVatGroupId = vm.stockAction.stockDocData.docVatGroupId;
      return payload;
    }
  },
  watch: {},
  methods: {
    onSave() {
      let vm = this;
      let savePayload = { ...vm.payload };
      this.update(savePayload);
    },
    update(finalPayload) {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(UPDATE_STOCK_DOC_DATA, {
              id: vm.stockAction.stockDocData.id,
              payload: finalPayload
            })
            .then(data => {
              vm.errors = data.data.errors;

              if (
                vm.source == "transport" &&
                vm.stockAction.invoiceId == null
              ) {
                vm.updateForwarderId();
              }
              if (vm.source == "addon" && vm.stockAction.invoiceId == null) {
                vm.updateContractorId();
              }
              //SET THE NEW STOCK ACTION STOCK DOC DATA;
              vm.stockAction.stockDocData = data.data;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: ""
              });
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    updateForwarderId() {
      let vm = this;
      let payload = {
        forwarderId: vm.stockAction.forwarderId
      };
      vm.$store
        .dispatch(UPDATE_STOCK_ACTION_TRANSPORT_FORWARDER_ID, {
          id: vm.stockAction.id,
          payload: payload
        })
        .then(data => {
          vm.errors = data.data.errors;
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    updateContractorId() {
      let vm = this;
      let payload = {
        contractorId: vm.stockAction.contractorId
      };
      vm.$store
        .dispatch(UPDATE_STOCK_ACTION_ADDON_CONTRACTOR_ID, {
          id: vm.stockAction.id,
          payload: payload
        })
        .then(data => {
          vm.errors = data.data.errors;
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    getSelects() {
      let vm = this;
      let payload = {
        forwarders: {
          fields: ["id", "name"],
          search: ""
        },
        contractors: {
          fields: ["id", "name"],
          search: ""
        },
        vat_groups: {
          fields: ["id", "name", "percent"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.forwarders = data.data.forwarders;
          vm.contractors = data.data.contractors;
          vm.vatGroups = data.data.vat_groups;
        })
        .catch(response => {
          console.log(response);
        });
    }
  }
};
</script>
